<template>
  <div v-if="tv ? 'tv-card-w' : 'w-80' " class="h-card overflow-ellipsis pt-6 rounded-lg px-2 relative min-w-80 w-80">
    <div>
      <div class="h-56 w-36 rounded-t-full rounded-b p-1 relative">
        <img :src="postArray.src" :alt="postArray.alt" style="-webkit-mask-image: radial-gradient(circle at left top, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.227) 60%, rgba(0, 0, 0, 0) 100%)"  class="mx-auto h-full overflow-hidden rounded-t-full rounded-b" >
      </div>
      <div class="px-4 py-3 text-left absolute top-16 left-16">
        <div v-if="size == 'medium'">
          <h4 class="font-heading text-h4 text-sepiaBlack dark:text-halfColonialWhite overflow-ellipsis overflow-hidden max-h-20">{{ postArray.title }}</h4>
        </div>
        <div v-else>
          <h5 class="font-heading text-h5 text-sepiaBlack dark:text-halfColonialWhite overflow-ellipsis overflow-hidden max-h-20">{{ postArray.title }}</h5>
        </div>
        <p class="font-body text-bodyM text-sepiaBlack dark:text-halfColonialWhite mt-4 overflow-ellipsis overflow-hidden max-h-20">{{ postArray.text }}</p>
        <!-- <a :href="postArray.link" class="flex cursor-pointer max-w-max">
            <span class="inline-block font-body text-bodyM dark:text-halfColonialWhite text-sepiaBlack my-auto">Entitás megnyitása</span>
            <BaseIcon name="arrowRight" outline= false class="inline-block" />
        </a> -->
      </div>
    </div>
  </div>
</template>

<script>
// import BaseIcon from '@/components/BaseIcon.vue';

export default {
  name: 'EntityListPortre',
  components: {
    // BaseIcon
  },
  props: {
    size: {
      type: String,
      require: false,
      default: 'medium'
    },
    content: {
      type: Object,
      require: true,
      default: () => {}
    },
    tv: {
      type: Boolean,
      require: false,
      default: false
    },
    itemIndex: {
      type: Number,
      require: false
    }
  },
  computed: {
    postArray() {
      return {
        title: this.content?.title,
        text: this.content?.sport_content,
        src: this.content?.img,
        alt: this.content?.alt,
        link: this.content?.link,
        id: this.content?.id
      }
    }
  },
  methods: {
    newIndex: function(value) {
      if(value >= 10) {
        return value + 1;
      } 
      return `0${value+1}`
    }
  }
}
</script>
